import { Vue, Component, Prop} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})
export default class DataDashboardQualityScoreAdd extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId: number = 0;
    public mobileView: boolean = false;
    @Prop()
    userDetails!:any;
    
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    mounted() {
        let {roleId} = APP_UTILITIES.coreids();
        this.roleId = roleId;
        this.mobileView = document.documentElement.clientWidth <= 768 ? true : false;
        window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
    }

    destroyed() {
        window.removeEventListener("resize", this.isMobile);
    }

    isMobile(){
        this.mobileView =APP_UTILITIES.isMobile()
    }

    close(){
        this.$emit('close', {id:'datadashboard-add-qualityscore'});
    }
    
}